.Button {
  color: white;
  border-radius: 0.4rem;
  border: none;
  font-family: 'Roboto';
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
}

.square {
 /* padding: 1.5rem;*/
}

.Button:active {
  opacity: 0.7;
}

.primary {
  background-color: var(--primaryButtonBackgroundColor);
  color: white;
}

.Button:disabled {
  opacity: 0.6;
}

.secondary {
  background-color: var(--secondaryButtonBackgroundColor);
  color: black;
}

.obligationGrey {
  background-color: var(--secondaryButtonBackgroundColor);
  color: rgba(0,0,0, 0.8);
}

.fullWidth {
  width: 100%;
}

