.TextField2 :global(.MuiTypography-colorTextSecondary) {
  margin-top: 17px !important;
}

.WithoutLabel {
  & :global(.MuiFilledInput-inputMarginDense) {
    padding-top: 10px !important;
  }
  & :global(.MuiFilledInput-multiline.MuiFilledInput-marginDense) {
    padding-top: 0px !important;
  }
  & :global(.MuiTypography-colorTextSecondary) {
    margin-top: 7px !important;
  }
  
}

.FixTextAreaHeight {
  & textarea {
   min-height: 1.6rem !important;
  }
}

.GreyedOut {
  & :global(.MuiFilledInput-root.Mui-disabled) {
    background-color: rgba(139,139,139,0.15) !important;
  }
}