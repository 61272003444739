.LoadingContainer {
  height: inherit;
  display:flex;
  justify-content: center;
  align-items: center;
}

.SpinnerSvg {
  -webkit-animation:spin 0.85s linear infinite;
  -moz-animation:spin 0.85s linear infinite;
  animation:spin 0.85s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


.LoadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}
