.UsagePaper {
  padding: 1rem;
  width: fit-content;

  & table {

    & td:nth-of-type(2n+1) {
      font-weight: 500;
      padding-right: 2rem;
      line-height:1.5;
    }

  }
}