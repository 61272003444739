
.TL {
  padding: 1rem;
 /* padding-left: 0 !important;*/

  display        : flex;
  justify-content: flex-start;
  align-items    : center;

  & > *:first-of-type {
    margin-top: 0;
  }
}

.TR {
  padding: 1rem;
 /* padding-right: 0 !important;*/

  display        : flex;
  justify-content: flex-end;
  align-items    : top;
}