.Paper {
  width: 30rem;
  min-height: 10rem;
  padding: 1rem 1.6rem;
  padding-bottom: 2rem;
  background: white;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0rem 0.1rem 0.6rem,
              rgba(0, 0, 0, 0.12) 0rem 0.1rem 0.4rem;
}
