:root {
  --navBarLeftBackgroundColor: #ca1010;
  --navBarRightBackgroundColor: #e67e54;
  --navBarMenuTextColor: #ffffff;
  --navBarMenuActiveColor: white;

  --titleColor: #e02200;
  --textColor: #0a0a0a;

  --pageControlActivePageBackgroundColor: #243794;
  --panelBackgroundColor: #f1f3f5;

  --badgeColor: #7ccb93;
  --inputPlaceholderColor: #8a96a0;

  --inputLabelColor: #cc3636;

  --primaryButtonBackgroundColor: #d30000;
  --secondaryButtonBackgroundColor: #f5b400;

  --loadingSpinnerColor: #ca1010;

  --checkboxColor: #e02200;
}
