.LoadingScreen {
  position: fixed;
  width   : 100%;
  height  : 100%;
  top     : 0;
  left    : 0;
  z-index : 1000;
  background: rgb(12,28,65);
  background: linear-gradient(135deg, var(--navBarLeftBackgroundColor) 0%, 
                                     var(--navBarRightBackgroundColor) 100%);


  & h1 {
    color: white;
    font-weight: 400;
    font-size: 4.5rem;
  }

  & .LogoSpinner {
    position: relative;

    & > img {
      position : absolute;
      top      : 50%;
      left     : 50%;
      transform: translate(-50%, -50%);
      width    : auto;
      height   : 14.2rem;
    }
  }
}

.hidden {
  display: none;
  opacity: 0.0;
}

.visible {
  opacity: 1.0;
}

.fadingIn {
  animation: fading_in 0.20s;
}

.fadingOut {
  animation: fading_out 0.20s;
}


@keyframes fading_in {
  0% { 
    opacity: 0.0; 
  }
  100% { 
    opacity: 1.0; 
  }
}

@keyframes fading_out {
  100% { 
    opacity: 0.0; 
    display: none;
  }
  0% { 
    opacity: 1.0; 
  }
}

