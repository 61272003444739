@import "./contants.module.scss";

html {
  overflow-y: hidden;
}

html, body, :global(div#root) {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', 'Source Sans Pro';
}

html, body {
  font-size: 10px;
}

:global(div#root) {
  font-size: 1.6rem;
}

:global(div:not(#root)) {
  font-size: 1.6rem;
}

:global(button) {
  cursor: pointer;
}

:global(.Container) {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  max-width: unset !important;
}

/** CONTAINER **/
@media (max-width:992px) {
  :global(.Container) {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    max-width: unset !important;
  }
}
@media (max-width:320px) {
  :global(.Container) {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
}

:global(.FakeLink) {
  color          : var(--inputLabelColor) !important;
  text-decoration: none !important;
  cursor         : pointer !important;
}
:global(.FakeLink:hover) {
  text-decoration: underline !important;
}

:global(.Link)
:global(.Link:link),
:global(.Link:active),
:global(.Link:visited) {
  color          : var(--inputLabelColor) !important;
  text-decoration: none !important;
  cursor         : pointer !important;
}
:global(.Link:hover) {
  text-decoration: underline !important;
}

:global(.Paper) {
  // padding       : 1rem 1.6rem ;
  // padding-bottom: 2rem ;
  // background    : white;
  // border-radius : 0.4rem ;
  // box-shadow    : rgba(0, 0, 0,  12%) 0rem 0.1rem 0.6rem, rgba(0, 0, 0,  12%) 0rem 0.1rem 0.4rem;
}