
.ModalOverlay {
  display        : flex;
  position       : fixed;
  top            : 0;
  left           : 0;
  width          : 100%;
  height         : 100%;
  max-height     : 100%;
  z-index        : 100;
  background     : rgba(0,0,0,0.25);
  justify-content: center;
  align-items    : center;
  overflow-y     : auto;
}

.ModalContainer {
  z-index   : 101;
  max-height: 100%;
}

.hidden {
  display: none;
  opacity: 0.0;
}

.visible {
  opacity: 1.0;
}

.fadingIn {
  animation: fading_in 0.25s;
}

.fadingOut {
  animation: fading_out 0.25s;
}

@keyframes fading_in {
  0% { 
    opacity: 0.0; 
    transform: translate(0, 0rem);
  }
  100% { 
    opacity: 1.0; 
    transform: translate(0, 0);
  }
}

@keyframes fading_out {
  100% { 
    opacity: 0.0; 
    transform: translate(0, 5rem);
  }
  0% { 
    opacity: 1.0; 
    transform: translate(0, 0);
  }
}



.bgHidden {
  opacity: 0.0;
}

.bgFadingIn {
  animation: bg_fading_in 0.25s;
}

.bgFadingOut {
  animation: bg_fading_out 0.25s;
}

.bgVisible {
  opacity: 1.0;
}

@keyframes bg_fading_in {
  0% { 
    opacity: 0.0; 
  }
  100% { 
    opacity: 1.0; 
  }
}

@keyframes bg_fading_out {
  100% { 
    opacity: 0.0; 
  }
  0% { 
    opacity: 1.0; 
  }
}
